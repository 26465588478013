import React, { useLayoutEffect } from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import loadable from '@loadable/component';

import { FAQ } from 'constants/navigation';
import { features } from 'gatsby-env-variables';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSmileBeam,
  faWatchFitness,
  faRecycle,
  faPeopleArrows,
} from '@fortawesome/pro-light-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const LazyHeadlineIconList = loadable(() => import('components/icon-list/landing-headline'));
const LazyNavCta = loadable(() => import('components/cta/navigiate'));

const LandingHeadline = ({ hash }) => {
  const styles = useStyles();

  const { faq = false } = features;

  useLayoutEffect(() => {
    const waitScroll = async () => {
      const scrollTo = await import('gatsby-plugin-smoothscroll');
      setTimeout(() => {
        scrollTo.default(hash);
      }, 2500);
    };
    if (hash) {
      waitScroll();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <span id="build-loyalty"/>
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} md={10} lg={8}>
        <Paper className={styles.paper} elevation={4}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          >
            <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h3"
              align="center"
              className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
              Build customer loyalty
              <FontAwesomeIcon icon={faSmileBeam} size="lg" className={styles.headerIcon}/>
              <Typography
                variant="caption"
                component="p"
                align="right"
                className={styles.headerSubText}>
                Loyal customers are returning customers
              </Typography>
            </Typography>
            </Grid>

            <Grid item md={10} sm={12} xs={12}>
            <span id="always-available"/>
            <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
              {'Total Loyalty is a software service that empowers small businesses with the ability to create Digital Loyalty Cards for '}
              <a
                href="https://support.apple.com/en-gb/HT204003"
                target="_blank" rel="noopener noreferrer"
                aria-label='Find out more about Apple Wallet'>
                {'Apple Wallet'}
              </a>
                {' and '}
              <a
                href="https://pay.google.com/intl/en_uk/about/learn/"
                target="_blank" rel="noopener noreferrer"
                aria-label='Find out more about Google Pay'>
                {'Google Pay'}
              </a>
              {`, to build stronger relationships with their customers. Backed by analytics, digital loyalty cards allow you to thank your customers for their trust and promote
                long-term loyalty. Encourage your customers to come back for more, all through their most trusted device — their smartphone.`}
            </Typography>
            </Grid>

            <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h4"
              align="center"
              className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
              Always Available
              <FontAwesomeIcon icon={faWatchFitness} size="lg" className={styles.headerIcon}/>
              <Typography
                variant="caption"
                component="p"
                align="right"
                className={styles.headerSubText}>
                Your brand on customers smartphones
              </Typography>
            </Typography>
            </Grid>

            <Grid item md={10} sm={12} xs={12}>
            <span id="greener-solution"/>
            <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
              {`We carry around our smartphones all the time and use them for nearly everything,
                from listening to music to boarding passes to ordering rides and so much more.
                Integrating with your customers mobile wallet enables your business to fit right into their routine.
                Providing a Total Loyalty digital card ensures your customers that they can always access their rewards,
                and this convenience could attract more of them to adopt your loyalty program.`}
            </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                align="center"
                className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
                Greener Solution
                <FontAwesomeIcon icon={faRecycle} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="p"
                  align="right"
                  className={styles.headerSubText}>
                  Lessen your carbon footprint and reduce pollutive waste
                </Typography>
              </Typography>
              </Grid>

              <Grid item md={10} sm={12} xs={12}>
              <span id="covid-aware"/>
              <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
                {`Digital loyalty cards don’t just save you money on the printing of physical stamp cards,
                  you also lessen your carbon footprint by reducing waste. Total Loyalty allows you to update
                  and share loyalty cards without the hassle of having to reprint and redistribute.
                  Your bottom-line will appreciate the expense save, your customers will appreciate fewer cards
                  that add clutter to their wallets, and the planet will appreciate the paperless solution.`}
              </Typography>
              </Grid>

              <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                align="center"
                className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
                Covid Aware
                <FontAwesomeIcon icon={faPeopleArrows} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="p"
                  align="right"
                  className={styles.headerSubText}>
                  Features to help with social distancing
                </Typography>
              </Typography>
              </Grid>

              <Grid item md={10} sm={12} xs={12}>
              <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
                {`In these uncertain times the way you interact with your customers may have changed, Total Loyalty would like help.
                 We held back the release of our software to add features that improved Total Loyalty's use in line social distancing recommendations. Total Loyalty
                 allows you to share digital loyalty cards simply and safely, through printable QR codes and via messaging and
                 email. Total Loyalty allows you to manage customer loyalty without your customer being present, simply search for your customers
                 digital loyalty card and stamp or redeem.`}
              </Typography>
              </Grid>

              <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h5"
                align="center"
                className={styles.headerBlue}>
                How It Works
              </Typography>
              <div className={styles.twoToFourBottomMargin}>
                <LazyHeadlineIconList/>
              </div>

              {faq && (
              <div className={styles.twoToFourBottomMargin}>
                <LazyNavCta
                  icon={faQuestionCircle}
                  route={FAQ}
                  primary="Want to find out more, try our FAQ."
                  primarySmall="try our FAQ"
                  secondary="We are always adding to our knowledge base."
                  />
              </div>
              )}
          </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    </>
  );
};

LandingHeadline.propTypes = { hash: string };

export default LandingHeadline;
